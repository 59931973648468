var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"modelsCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"MonitorIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("DHCP Reservation Details")])],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"DHCP Server","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"DHCP Server","rules":"required|max:255","vid":"server"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"name","placeholder":"Select available DHCP server","options":_vm.networks,"is-open":"","open":"","return-object":"","filled":"","searchable":true},on:{"search":_vm.onSearchNetwork},model:{value:(_vm.network),callback:function ($$v) {_vm.network=$$v},expression:"network"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"MAC Address","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"MAC Address","rules":"max:255","vid":"mac_address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter or select available MAC address","autocomplete":"off"},model:{value:(_vm.form.mac_address),callback:function ($$v) {_vm.$set(_vm.form, "mac_address", $$v)},expression:"form.mac_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"IP Address","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"IP Address","rules":"max:255","vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-ip',{attrs:{"errors":errors,"placeholder":"Enter or select IP Address","autocomplete":"off","onchange":function (value) { return _vm.form.address = value; },"model":_vm.form.address,"inputs-id":"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Host name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Host name","rules":"max:255","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter host name","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Address list","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address list","rules":"max:255","vid":"address_lists"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"list","placeholder":"Select one or more addresses","options":_vm.address_lists,"searchable":true},model:{value:(_vm.form.address_lists),callback:function ($$v) {_vm.$set(_vm.form, "address_lists", $$v)},expression:"form.address_lists"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Comment","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Comment","rules":"max:255","vid":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter your notes about reservation here","autocomplete":"off"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{staticClass:"text-right buttons-container__left",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","ok-variant":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('b-button-request',{attrs:{"text":"Update","request":_vm.validationForm}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }